import {
  onEvent,
  TRACK_INTERACTION,
  triggerEvent,
} from '@fec/assets/js/utils/event';
import { readUrnFromMetaTag } from '@fec/assets/js/utils/meta-tags';
import Urn from '@fec/frontend/foundation/client/urn';

// wait timeout in seconds:
const TIMEOUT = 30;
let timeoutId = null;

const pageType = Urn.getType(readUrnFromMetaTag());

export const init = () => {
  onEvent({
    eventName: 'scroll',
    eventHandler: () => {
      clearTimeout();
      timeoutId = window.setTimeout(triggerKeepalive, TIMEOUT * 1000);
    },
    passive: true,
  });

  onEvent({
    eventName: 'beforeunload',
    eventHandler: () => clearTimeout(),
  });
};

const clearTimeout = () => timeoutId !== null && window.clearTimeout(timeoutId);

const triggerKeepalive = () => {
  triggerEvent(TRACK_INTERACTION, {
    event_source: pageType,
    event_name: 'keepalive',
    event_type: 'hidden_event',
  });
};
