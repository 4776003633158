import { onEvent, SET_META_THEME_COLOR } from '@fec/assets/js/utils/event';

/*
 * This script
 * #1 sets the current masthead-background-color as the (meta) theme-color
 * #2 lets you trigger a change of the (meta) theme-color (via custom event)
 * #3 is able to reset a changed (meta) theme-color to its original value
 *
 * For #1 (and #3), this script gets the background-color of the `masthead` from
 * the CSS of the current document. The BG-Color is (mostly) available as the
 * value of the css custom prop `--t-masthead-bg`.
 *
 * For #2, this init listens to our custom-event and sets the transmitted value
 * as the new MetaThemeColor-Value
 */
export function init() {
  const canSetThemeColor = document.querySelector('.js-masthead') !== null;
  let originalColor;
  if (canSetThemeColor) {
    originalColor = window
      .getComputedStyle(document.querySelector('.js-masthead'))
      .getPropertyValue('--t-masthead-bg');

    /* … and sets this `originalColor` as the new MetaThemeColor-Value */
    setMetaThemeColor(originalColor);

    onEvent({
      eventName: SET_META_THEME_COLOR,
      eventHandler: ({ detail }) => setMetaThemeColor(detail, originalColor),
    });
  }
}

/*
 * This is where (most of) the magic happens™. 🪄
 *
 * 1) If the meta-tag `theme-color` already exists, set `content` of that tag
 * to the specified color – which is a) the color that was transmitted via the
 * function-caller OR b) the initially read-out originalColor
 *
 * 2) If the meta-tag `theme-color` does not yet exist, create a new meta-elem
 * with the name `theme-color` and append it to the end of the `<head />` of the
 * current document. Then set `content` of that newly created meta-tag to the
 * specified color (== transmitted value or originalColor-fallback)
 *
 * For #3 we use originalColor as a fallback if there is no color specified as
 * argument of this function.
 */
function setMetaThemeColor(color, originalColor) {
  let metaThemeColorTag = document.querySelector('meta[name="theme-color"]');

  if (!metaThemeColorTag) {
    metaThemeColorTag = document.createElement('meta');
    metaThemeColorTag.name = 'theme-color';
    document.getElementsByTagName('head')[0].appendChild(metaThemeColorTag);
  }

  metaThemeColorTag.setAttribute('content', color ?? originalColor);
}
