import ResponsiveHelper from '@fec/frontend/foundation/client/responsive-helper';
import {
  FOCUS_MOVE,
  onEvent,
  TRACK_INTERACTION,
  SWIPEABLE_CONTENT_CHANGED,
  triggerEvent,
} from '@fec/assets/js/utils/event';
import TouchDetection from '@fec/frontend/foundation/client/touch';

const COLLECTION = '.js-collection-ng-swipeable';
const ITEM_CONTAINER = '.js-collection-ng-swipeable-container';
const CONTROLS_CONTAINER = '.js-collection-ng-swipeable-controls';
const BUTTON_PREVIOUS = '.js-swipeable-control-left';
const BUTTON_NEXT = '.js-swipeable-control-right';
const ITEM_HOOK = '.js-teaser-ng-item';

export function init() {
  $(COLLECTION).each((_, element) => {
    new CollectionNgSwipeable(element);
  });
}

export class CollectionNgSwipeable {
  constructor(collection) {
    this.collection = collection;
    this.itemContainer = this.collection.querySelector(ITEM_CONTAINER);
    this.controlsContainer = this.collection.querySelector(CONTROLS_CONTAINER);
    this.buttonPrevious = this.collection.querySelector(BUTTON_PREVIOUS);
    this.buttonNext = this.collection.querySelector(BUTTON_NEXT);

    // only used for safari fallback (see initListeners())
    this.safariItemObserver = null;

    this.initListeners();
    this.updateControls();
    this.initObservers();
  }

  initListeners() {
    if ('onscrollend' in window) {
      this.itemContainer.addEventListener('scrollend', () =>
        this.handleScrollEnd(),
      );
    } else {
      // fallback for safari (which does not support `onscrollend`)
      this.safariItemObserver = new IntersectionObserver(
        () => this.handleScrollEnd(),
        {
          root: this.itemContainer,
          threshold: 1,
        },
      );

      // observe only one item per page for better performance in safari
      const itemVisibilityList = this.getItemVisibilityList();
      const pageSize = itemVisibilityList.filter((element) => element).length;
      let i = 0;
      [...this.itemContainer.children].forEach((item) => {
        if (i % pageSize === 0) {
          this.safariItemObserver.observe(item);
        }
      });
    }

    // handle content changes in the collection
    onEvent({
      eventName: SWIPEABLE_CONTENT_CHANGED,
      eventHandler: () => {
        this.handleContentChanges();
      },
      element: this.collection,
    });

    // handle click on teasers
    this.itemContainer.addEventListener('click', (e) => {
      const item = e.target.closest(ITEM_HOOK);
      if (item) {
        this.handleTeaserClick(e, item);
      }
    });

    // handle click on teasers
    this.itemContainer.addEventListener('focusin', (e) => {
      const item = e.target.closest(ITEM_HOOK);
      if (item) {
        this.handleTeaserFocus(item);
      }
    });

    // control button previous
    this.buttonPrevious.addEventListener('click', (e) =>
      this.handlePrevious(e),
    );

    // control button next
    this.buttonNext.addEventListener('click', (e) => this.handleNext(e));
  }

  initObservers() {
    // when the size of the collection changes, update the control buttons
    const collectionResizeObserver = new ResizeObserver(() =>
      this.updateControls(),
    );
    collectionResizeObserver.observe(this.collection);
  }

  handleContentChanges() {
    // the fallback for safari needs new items to be observed too
    if (this.safariItemObserver) {
      const itemVisibilityList = this.getItemVisibilityList();
      const pageSize = itemVisibilityList.filter((element) => element).length;
      let i = 0;
      [...this.itemContainer.children].forEach((item) => {
        this.safariItemObserver.unobserve(item);
        // observe only one item per page for better performance in safari
        if (i % pageSize === 0) {
          this.safariItemObserver.observe(item);
        }
      });
    }
    this.updateControls();
  }

  handleScrollEnd() {
    this.updateControls();
    if (!this.safariItemObserver) {
      // No focus event triggered for safari since it causes ugly scrolling behaviour in safari
      requestAnimationFrame(() => triggerEvent(FOCUS_MOVE));
    }

    // inform other components that the collection has been scrolled - e.g. for autoplay on mobile devices!
    triggerEvent('scroll', null, this.collection);
  }

  handleTeaserClick(event, item) {
    // remove focus from the element that was just clicked
    // if it was a mouse click
    if (TouchDetection.eventIsMouseclick(event)) {
      item.blur();
    }

    // moving partially visible items into the viewport
    // instead of following the item's link
    if (!this.isFullyVisible(item)) {
      event.preventDefault();
      event.stopPropagation();
      item.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'nearest', // use 'nearest' to avoid page shifting when last item is visible and 'prev' button is clicked - chrome bug?
      });
      return false;
    }
  }

  handleTeaserFocus(item) {
    // moving partially visible items into the viewport
    if (!this.isFullyVisible(item)) {
      item.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }

  updateControls() {
    this.setControlsTopPosition();
    if (
      ResponsiveHelper.isDesktopUp() &&
      (this.hasScrollableNextOverflow() || this.hasScrollablePreviousOverflow())
    ) {
      this.controlsContainer.classList.toggle('h-element--hide', false);
      // activate `previous` button, if there's scrollable content to the left
      this.buttonPrevious.classList.toggle(
        'collection-ng__swipeable-button--inactive',
        !this.hasScrollablePreviousOverflow(),
      );
      // activate `next` button, if there's scrollable content to the right
      this.buttonNext.classList.toggle(
        'collection-ng__swipeable-button--inactive',
        !this.hasScrollableNextOverflow(),
      );
    } else {
      // controls are hidden via CSS on mobile and tablet but we have
      // to hide the controls on desktop-up too if there is no overflow
      this.controlsContainer.classList.toggle('h-element--hide', true);
    }
  }

  // set the top position of the collection controls so that they are in the middle of the item container
  setControlsTopPosition() {
    this.controlsContainer.style.top =
      this.itemContainer.getBoundingClientRect().top -
      this.collection.getBoundingClientRect().top +
      this.itemContainer.getBoundingClientRect().height / 2 -
      this.controlsContainer.getBoundingClientRect().height / 2 -
      parseInt(window.getComputedStyle(this.itemContainer).paddingBottom) / 2 +
      'px';
  }

  getItemVisibilityList() {
    let itemContainerRect = this.itemContainer.getBoundingClientRect();
    return [...this.itemContainer.children].map((item) =>
      this.isFullyVisible(item, itemContainerRect),
    );
  }

  handlePrevious() {
    this.doScroll('previous');
    this.track('click-left');
  }

  handleNext() {
    this.doScroll('next');
    this.track('click-right');
  }

  doScroll(direction) {
    let itemVisibilityList = this.getItemVisibilityList();
    let numberOfItems = this.itemContainer.childElementCount;
    let pageSize = itemVisibilityList.filter((element) => element).length;
    let currentIndex = itemVisibilityList.findIndex((element) => element);

    let nextTargetIndex =
      direction === 'previous'
        ? Math.max(currentIndex - pageSize, 0)
        : Math.min(currentIndex + pageSize, numberOfItems - 1);

    // on wide screens, the collection has a fixed max-width and therefore the "bleed" is variable, which
    // needs to be considered in the calculation of the target scroll position
    const scrollCorrection = ResponsiveHelper.isDesktopUp()
      ? (window.innerWidth - this.itemContainer.getBoundingClientRect().width) /
        2
      : 0;

    this.itemContainer.scrollTo({
      left:
        this.itemContainer.children[nextTargetIndex].offsetLeft -
        scrollCorrection,
      behaviour: 'auto',
    });
  }

  hasScrollableNextOverflow() {
    return (
      this.itemContainer.scrollWidth - this.itemContainer.scrollLeft >
      this.itemContainer.clientWidth
    );
  }

  hasScrollablePreviousOverflow() {
    return this.itemContainer.scrollLeft > 0;
  }

  isFullyVisible(item, itemContainerRect) {
    if (itemContainerRect === undefined) {
      itemContainerRect = this.itemContainer.getBoundingClientRect();
    }
    let itemRect = item.getBoundingClientRect();
    return (
      itemRect.left >= itemContainerRect.left &&
      itemRect.right <= itemContainerRect.right
    );
  }

  track(eventValue) {
    triggerEvent(TRACK_INTERACTION, {
      event_type: 'hidden_event', // OG collections send this event type too although it's in fact a click
      event_source: this.itemContainer.dataset.eventSource,
      event_name: this.itemContainer.dataset.eventName,
      event_value: eventValue,
    });
  }
}
