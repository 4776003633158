import { ResponsiveHelper } from '@fec/frontend/foundation/client/responsive-helper';

const ELEMENT_SELECTOR = '.js-background-image-update';

export function init() {
  if (ResponsiveHelper.isMobile()) {
    $(ELEMENT_SELECTOR).each((_, element) => {
      const $element = $(element);
      const mobileBreakpointURL = $element.attr('data-bg-alternative');

      $element.attr('data-bg', mobileBreakpointURL);
    });
  }
}
