import { DebounceHelper } from '@fec/frontend/foundation/client/debounce-helper';
import { ResponsiveHelper } from '@fec/frontend/foundation/client/responsive-helper';
import { ResizeListener } from '@fec/frontend/foundation/client/resize-listener';
import { KEYCODES } from '@fec/frontend/foundation/utils/keycodes';
import {
  FOCUS_MOVE,
  onEvent,
  SUBNAV_CLOSE,
  triggerEvent,
} from '@fec/assets/js/utils/event';

const SUBNAV_CLASS = 'js-subnav-container',
  INNER_CONTAINER_CLASS = 'js-subnav-content',
  PAGER_BACK_CLASS = 'js-subnav-pager-back',
  PAGER_FORWARD_CLASS = 'js-subnav-pager-forward',
  PAGER_ACTIVE_CLASS = 'subnav__pager--visible',
  PAGER_BUTTON_CLASS = 'js-subnav-pager-button',
  MASK_LEFT_CLASS = 'js-subnav-mask-left',
  MASK_RIGHT_CLASS = 'js-subnav-mask-right',
  MASK_VISIBLE_CLASS = 'subnav__mask--visible',
  ITEM_CLASS = 'js-nav-item',
  ITEM_ACTIVE_CLASS = 'js-active-nav-item',
  ITEM_GROUP_CLASS = 'js-nav-group',
  ITEM_OPEN_GROUP_CLASS = 'js-nav-group-open',
  ITEM_GROUP_WRAPPER_CLASS = 'js-nav-group-wrapper',
  OUTSIDE_CLICK_LISTENER_NAME = 'click.nav-group',
  OUTSIDE_KEYPRESS_LISTENER_NAME = 'keydown.nav-group',
  DEBOUNCETIME = 10,
  THROTTLETIME = 100,
  RIGHT_OFFSET = 24,
  BUTTON_BACK_THRESHOLD = 2,
  INNER_CONTAINER_SCROLL_PADDING = ResponsiveHelper.isMobile() ? 12 : 64,
  ITEM_GROUP_MARGIN = 16,
  DEFAULT_SCROLL_TIME = 200;

export function init() {
  $(`.${SUBNAV_CLASS}`).each((_, element) => {
    new Subnav($(element));
  });
}

/**
 * This component handles the behaviour of the subnav, such as paging buttons and paging
 * functionality of a horizontally scrollable items list as well as the masks for
 * overflowing navigation items and 3rd-level navigation.
 */
export class Subnav {
  /**
   * @param $element jQuery element
   */
  constructor($element) {
    this.itemLeftPositions = [];
    this.itemRightPositions = [];
    this.$element = $element;
    this.$innerContainer = $(`.${INNER_CONTAINER_CLASS}`, this.$element);
    this.$buttonBack = $(`.${PAGER_BACK_CLASS}`, this.$element);
    this.$buttonForward = $(`.${PAGER_FORWARD_CLASS}`, this.$element);
    this.$maskLeft = $(`.${MASK_LEFT_CLASS}`, this.$element);
    this.$maskRight = $(`.${MASK_RIGHT_CLASS}`, this.$element);
    this.subNaviCloseLock = false;

    this.updateControls();
    this.initItemPositions();
    this.registerListeners();
    this.centerActiveItem();
  }

  updateControls() {
    this.updateButtonStatus();
    this.updateMaskStatus();
  }

  initItemPositions() {
    this.$innerContainer.children().each((_, element) => {
      this.itemLeftPositions.push($(element).position().left);
      this.itemRightPositions.push(
        $(element).position().left + $(element).innerWidth(),
      );
    });
  }

  onResize() {
    this.closeAllSubNavs();
    this.updateControls();
  }

  registerListeners() {
    ResizeListener.subscribeDebounced(() => this.onResize());

    this.$innerContainer[0].addEventListener(
      'scroll',
      DebounceHelper.debounce(() => this.updateControls(), DEBOUNCETIME),
      { passive: true },
    );

    this.$innerContainer[0].addEventListener(
      'scroll',
      DebounceHelper.throttle((e) => this.handleScroll(e), THROTTLETIME),
      { passive: true },
    );

    this.$buttonBack
      .find(`.${PAGER_BUTTON_CLASS}`)
      .on('click', () => this.pageBack());

    this.$buttonForward
      .find(`.${PAGER_BUTTON_CLASS}`)
      .on('click', () => this.pageForward());

    // A focus event is always triggered BEFORE the click event. This prevents the click event from being triggered in
    // some edge cases (i.e. when clicking nav items that are just partially visible in the viewport).
    //
    // To avoid this, we prevent the default action (= focus) on mousedown (which happens BEFORE the focus event)
    // event order: mousedown -> focus -> click
    this.$element.find(`.${ITEM_CLASS}`).on('mousedown', (e) => {
      e.preventDefault(); // prevent the focus event from being triggered
    });

    // On focus, check if the focussed item is out of the viewport.
    // If so, scroll the item into view and let the flying focus know, so
    // that it's repositioned correctly.
    this.$element.find(`.${ITEM_CLASS}`).on('focus', (e) => {
      if (this.hasScrollableOverflow()) {
        // make sure that the subNaviCloseLock is reset (if the lock's timeout is still running)
        this.scrollFocussedElementIntoView(e.currentTarget);
      }
    });

    // On click, if the clicked item has a 3rd level subnav:
    // 1. check if the item is out of the viewport. If so, scroll it into view.
    // 2. open the subnav
    this.$element.on('click', `.${ITEM_GROUP_CLASS}`, (e) => {
      if ($(e.target).parent().hasClass(ITEM_GROUP_CLASS)) {
        e.preventDefault();
        e.stopPropagation();
        if (this.hasScrollableOverflow()) {
          this.scrollFocussedElementIntoView(e.currentTarget);
        }
        // make sure that the subNaviCloseLock is reset (if the lock's timeout is still running)
        this.subNaviCloseLock = false;
        this.toggleSubNav($(e.currentTarget));
      }
    });

    onEvent({
      eventName: SUBNAV_CLOSE,
      eventHandler: () => this.closeAllSubNavs(),
    });
  }

  handleScroll() {
    // firefox sends scroll events just after a click on 2nd level nav items with children. this would close a
    // corresponding 3rd level nav immediately after opening. that's why we check for a subNaviCloseLock here.
    if (!this.subNaviCloseLock) {
      this.closeAllSubNavs();
    }
  }

  closeAllSubNavs() {
    $(document).off(
      `${OUTSIDE_CLICK_LISTENER_NAME} ${OUTSIDE_KEYPRESS_LISTENER_NAME}`,
    );
    let $openNavs = this.$element.find(`.${ITEM_OPEN_GROUP_CLASS}`);

    $openNavs.each((_, el) => this.closeSubNav($(el)));
  }

  /**
   * Close a subnav-group by fading it out and then setting the styles/classes etc.
   */
  closeSubNav($navGroup) {
    let $wrapper = $navGroup.find(`.${ITEM_GROUP_WRAPPER_CLASS}`);

    this.$element.removeClass('subnav--open-3rd-level');
    $('body').removeClass('l-dimmed-background');

    $navGroup
      .removeClass(`${ITEM_OPEN_GROUP_CLASS} nav-group--open`)
      .children('a')
      .attr({ 'aria-expanded': false, 'aria-haspopup': false });

    // reset previously applied styles
    $wrapper.css({ transform: '', 'max-height': '' });
    $wrapper.find('.nav-group__list').width('');
  }

  // do it the lazy way - close all subnavs, open the desired one (if it was open)
  toggleSubNav($navGroup) {
    // firefox sends scroll events just after a click on 2nd level nav items with children. this would close a
    // corresponding 3rd level nav immediately after opening. that's why we set (and reset) a temporary subNaviCloseLock here.
    this.subNaviCloseLock = true;
    setTimeout(() => {
      this.subNaviCloseLock = false;
    }, 500);

    let isClosing = $navGroup.hasClass(ITEM_OPEN_GROUP_CLASS);
    this.closeAllSubNavs();
    if (isClosing) {
      return;
    }

    $navGroup
      .children('a')
      .attr({ 'aria-expanded': true, 'aria-haspopup': true });

    // Make space for the 3rd level nav
    this.$element.addClass('subnav--open-3rd-level');

    // Slightly dim the page
    $('body').addClass('l-dimmed-background');

    // Listen to clicks outside of the element and Escape keypress --> close element
    $(document)
      .on(OUTSIDE_CLICK_LISTENER_NAME, (e) => {
        if (!$(e.target).hasClass('nav-item')) {
          this.closeAllSubNavs();
        }
      })
      .on(OUTSIDE_KEYPRESS_LISTENER_NAME, (e) => {
        if (e.keyCode === KEYCODES.escape) {
          this.closeAllSubNavs();
        }
      });

    $navGroup.addClass(`${ITEM_OPEN_GROUP_CLASS} nav-group--open`);

    if (!ResponsiveHelper.isMobile()) {
      this.positionAndStretchSubNavGroup($navGroup);
    } else {
      // Mobile: don't let the nav-group be taller than the space under the masthead.
      // Make it scrollable and set a max-height to guarantee it.
      $navGroup.find(`.${ITEM_GROUP_WRAPPER_CLASS}`).css({
        'max-height': `calc(100vh - ${$('.js-masthead').outerHeight(
          true,
        )}px - ${$('.js-masthead-subnav').outerHeight(true)}px)`,
      });
    }

    // Close subnav when tabbing out of it
    const $lastNavItem = $navGroup.find('.nav-group__item').last();
    $lastNavItem.on('focusin', () => {
      $lastNavItem.on('keydown', (e) => {
        if (e.keyCode === KEYCODES.tab && !e.shiftKey) {
          this.closeSubNav($navGroup);
          $lastNavItem.off('focusin');
          $lastNavItem.off('keydown');
        }
      });
    });
  }

  positionAndStretchSubNavGroup($navGroup) {
    const $list = $navGroup.find('.nav-group__list');
    const $listWrapper = $navGroup.find(`.${ITEM_GROUP_WRAPPER_CLASS}`);

    // If the parental nav group is wider than the list, make them the same size (compensate for margin)
    if ($navGroup.outerWidth() + ITEM_GROUP_MARGIN > $list.outerWidth()) {
      $list.width($navGroup.outerWidth() + ITEM_GROUP_MARGIN);
    }

    // If the lists right edge is out of the viewport, align it with the right side of the window
    const rightEdgeDiff =
      $listWrapper[0].getBoundingClientRect().right -
      this.$element[0].getBoundingClientRect().right;
    if (rightEdgeDiff > 0) {
      $listWrapper.css({
        transform: `translateX(calc((${rightEdgeDiff}) * -1px))`,
      });
    }
  }

  updateButtonStatus() {
    if (ResponsiveHelper.isDesktopUp()) {
      // show forward button if needed
      if (this.isAtScrollEnd()) {
        this.$buttonForward.removeClass(PAGER_ACTIVE_CLASS);
      } else if (this.hasScrollableOverflow()) {
        this.$buttonForward.addClass(PAGER_ACTIVE_CLASS);
      } else {
        this.$buttonForward.removeClass(PAGER_ACTIVE_CLASS);
      }

      // show back button if needed
      if (
        this.hasScrollableOverflow() &&
        this.$innerContainer.scrollLeft() > BUTTON_BACK_THRESHOLD
      ) {
        this.$buttonBack.addClass(PAGER_ACTIVE_CLASS);
      } else {
        this.$buttonBack.removeClass(PAGER_ACTIVE_CLASS);
      }
    }
  }

  updateMaskStatus() {
    if (ResponsiveHelper.isMobile() || ResponsiveHelper.isTablet()) {
      // show right mask if needed
      if (this.isAtScrollEnd()) {
        this.$maskRight.removeClass(MASK_VISIBLE_CLASS);
      } else if (this.hasScrollableOverflow()) {
        this.$maskRight.addClass(MASK_VISIBLE_CLASS);
      } else {
        this.$maskRight.removeClass(MASK_VISIBLE_CLASS);
      }

      // show left mask if needed
      if (
        this.hasScrollableOverflow() &&
        this.$innerContainer.scrollLeft() > BUTTON_BACK_THRESHOLD
      ) {
        this.$maskLeft.addClass(MASK_VISIBLE_CLASS);
      } else {
        this.$maskLeft.removeClass(MASK_VISIBLE_CLASS);
      }
    }
  }

  pageForward() {
    const visibleAreaRightEdge =
      this.$innerContainer.scrollLeft() + this.$innerContainer.innerWidth();
    let nextItemIndex = this.itemRightPositions.findIndex(
      (rightEdge) => rightEdge > visibleAreaRightEdge,
    );

    if (nextItemIndex < 0) {
      nextItemIndex = this.itemRightPositions.length - 1;
    }

    const newPosition =
      this.itemLeftPositions[nextItemIndex] - INNER_CONTAINER_SCROLL_PADDING;

    this.scrollToPosition(newPosition);
  }

  pageBack() {
    const visibleAreaLeftEdge =
      this.$innerContainer.scrollLeft() + INNER_CONTAINER_SCROLL_PADDING;
    let nextItemIndex = this.itemRightPositions.findIndex(
      (rightEdge) => rightEdge > visibleAreaLeftEdge,
    );

    if (nextItemIndex < 0) {
      nextItemIndex = 0;
    }

    const newPosition =
      this.itemLeftPositions[nextItemIndex] -
      this.$innerContainer.innerWidth() +
      INNER_CONTAINER_SCROLL_PADDING;

    this.scrollToPosition(newPosition);
  }

  centerActiveItem() {
    const $active = $(`.${ITEM_ACTIVE_CLASS}`, this.$element);

    if ($active.length !== 1) {
      return;
    }

    this.scrollFocussedElementIntoView($active[0]);
  }

  scrollFocussedElementIntoView(element) {
    let newPosition = 0;

    const subnavPosition = this.$element[0].getBoundingClientRect();
    const itemPosition = element.getBoundingClientRect();
    const subnavScrollPosition = this.$innerContainer[0].scrollLeft;

    if (
      subnavPosition.left + INNER_CONTAINER_SCROLL_PADDING >
      itemPosition.left
    ) {
      newPosition =
        subnavScrollPosition -
        (subnavPosition.left - itemPosition.left) -
        INNER_CONTAINER_SCROLL_PADDING;
    } else if (
      subnavPosition.right - INNER_CONTAINER_SCROLL_PADDING <
      itemPosition.right
    ) {
      newPosition =
        subnavScrollPosition +
        (itemPosition.right - subnavPosition.right) +
        INNER_CONTAINER_SCROLL_PADDING;
    }

    if (newPosition !== 0) {
      this.scrollToPosition(newPosition, 0);
      // inform the flying focus that the focussed element moved
      triggerEvent(FOCUS_MOVE);
    }
  }

  scrollToPosition(position, time) {
    time = typeof time === 'undefined' ? DEFAULT_SCROLL_TIME : time;

    this.$innerContainer
      .stop(true, false)
      .animate({ scrollLeft: position }, time);
  }

  hasScrollableOverflow() {
    return (
      this.$innerContainer[0].scrollWidth >
      this.$innerContainer.innerWidth() + RIGHT_OFFSET
    );
  }

  isAtScrollEnd() {
    const safetyMargin = 4; // prevent some browsers' rounding issues
    return (
      this.$innerContainer[0].scrollLeft + this.$innerContainer.innerWidth() >=
      this.$innerContainer[0].scrollWidth - safetyMargin
    );
  }
}
