import ClientStorage from '@fec/frontend/foundation/client/storage';

const MEDIA_PROGRESS_STORAGE_KEY = 'srf:item:media:progress';
const MEDIA_PROGRESS_LIMIT = 150;

export const getMediaProgressList = () =>
  ClientStorage.getItemJsonParsed(MEDIA_PROGRESS_STORAGE_KEY, []);

export const setMediaProgressList = (list) =>
  ClientStorage.setItemJsonStringified(MEDIA_PROGRESS_STORAGE_KEY, list);

export const getMediumProgress = (urn) => {
  const list = getMediaProgressList();
  return list.find((entry) => entry.urn === urn);
};

export const setMediumProgress = (urn, seconds, duration) => {
  let list = getMediaProgressList();

  // remove current item, if existing
  list = list.filter((entry) => entry.urn !== urn);

  // add new/updated entry to the end of the list
  list.push({
    urn,
    seconds,
    duration,
  });

  // make sure list doesn't get too long (this removes the oldest entries)
  list = list.slice(-MEDIA_PROGRESS_LIMIT);

  setMediaProgressList(list);
};

export const getMediumProgressPercentage = (urn) => {
  const progress = getMediumProgress(urn);
  if (!progress) {
    return 0;
  }

  const { seconds, duration } = progress;
  const percentage = (seconds / duration) * 100;
  // make sure percentage is always between 0 and 100
  const clampedPercentage = Math.min(Math.max(percentage, 0), 100);

  return clampedPercentage;
};

export const getDuration = (duration) => {
  const roundedDuration = Math.floor(Math.round(duration / 1000));
  const minutes = Math.floor(roundedDuration / 60);
  const seconds = roundedDuration % 60;

  return {
    minutes: minutes,
    seconds: seconds,
  };
};

export const getA11yDuration = (duration, t) => {
  const { minutes, seconds } = getDuration(duration);

  let a11yDuration = '';
  if (minutes === 1) {
    a11yDuration = `${minutes} ${t('Minute')}`;
  } else if (minutes > 1) {
    a11yDuration = `${minutes} ${t('Minuten')}`;
  }

  if (seconds === 1) {
    a11yDuration = `${a11yDuration} ${seconds} ${t('Sekunde')}`;
  } else if (seconds > 1) {
    a11yDuration = `${a11yDuration} ${seconds} ${t('Sekunden')}`;
  }

  return a11yDuration;
};

export const getFormattedDuration = (duration) => {
  const { minutes, seconds } = getDuration(duration);

  const formattedMinutes = (minutes + '').padStart(2, '0');
  const formattedSeconds = (seconds + '').padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

/**
 * Returns "04:20" formatted string for the progress of a medium, if available.
 *
 * @param urn
 * @returns {string|null}
 */
export const getMediumProgressFormatted = (urn) => {
  const progress = getMediumProgress(urn);
  if (!progress) {
    return null;
  }

  return getFormattedDuration(progress.seconds * 1000);
};
