export function init() {
  initEventlistener();
}

function initEventlistener() {
  $(document).on('click', '.js-copy-to-clipboard', (e) => {
    e.preventDefault();

    //button--success
    const $target = $(e.currentTarget);

    $target.removeClass('button--secondary button--success button--on-error');
    $target.find('.svg-icon').removeClass('svg-icon--clipboard');

    writeText($target.data('clipboard-data')).then(
      () => {
        if ($target.hasClass('js-copy-to-clipboard-link')) {
          $target
            .find('.svg-icon')
            .addClass('svg-icon--checkmark-circled menu-link__success-icon');
          $target
            .find('.menu-link__text')
            .addClass('menu-link__text--success')
            .text($target.data('successText'));
        } else {
          $target
            .addClass('button--success')
            .find('.button-text')
            .text($target.data('successText'));
        }
      },
      () => {
        if ($target.hasClass('js-copy-to-clipboard-link')) {
          $target.find('.svg-icon').addClass('svg-icon--error');
          $target
            .find('.menu-link__text')
            .addClass('menu-link__text--on-error')
            .text($target.data('failText'));
        } else {
          $target
            .addClass('button--on-error')
            .find('.button-text')
            .text($target.data('failText'));
        }
      },
    );
  });

  $(document).on('click', '.js-instant-share', (e) => {
    e.preventDefault();
    const $target = $(e.currentTarget);

    window.open(
      $target.data('share-url'),
      '',
      `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${$target.data(
        'share-height',
      )},width=${$target.data('share-width')}`,
    );
  });
}

function writeText(str) {
  return new Promise(function (resolve, reject) {
    /********************************/
    let range = document.createRange();
    range.selectNodeContents(document.body);
    document.getSelection().addRange(range);
    /********************************/

    let success = false;
    function listener(e) {
      e.clipboardData.setData('text/plain', str);
      e.preventDefault();
      success = true;
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    /********************************/
    document.getSelection().removeAllRanges();
    /********************************/

    success ? resolve() : reject();
  });
}
