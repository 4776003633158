/*
 * SRG LETTERBOX WEB PLAYER
 *
 * INFOS about the player:
 * Documentation: https://srgletterbox-web.s3.eu-central-1.amazonaws.com/production/api/index.html
 * Playground: https://srgssr.github.io/srgletterbox-web-demo
 *
 */
/* global SRGLetterbox */
import { getConfig } from '@fec/assets/js/utils/config';
import {
  MEDIA_READY,
  MEDIA_FULLSCREEN_CHANGE,
  MEDIA_CAN_PLAY,
  MEDIA_ENDED,
  MEDIA_ERROR,
  MEDIA_PAUSED,
  MEDIA_PLAYING,
  MEDIA_TIME_UPDATE,
  MEDIA_VOLUME_CHANGED,
  triggerEvent,
} from '@fec/assets/js/utils/event';
import { isChrome } from '@fec/assets/js/utils/browsers.js';

export const GENERATED_PLAYER_CONTAINER = 'js-player-container';

export const COMPONENTS_PRESETS = {
  DEFAULT: {
    controlBar: true,
    header: true,
    sharing: true,
    title: false,
    thumbnails: false, // disabled until PLAYRTS-3314 is fixed or a solution is found
    playButton: true,
    subdivisions: false,
  },
  ALL_DISABLED: {
    controlBar: false,
    header: false,
    sharing: false,
    title: false,
    thumbnails: false,
    playButton: false,
    subdivisions: false,
  },
};

export class SrfLetterboxPlayer {
  constructor(options = {}) {
    const {
      containerWrapperId,
      assetId,
      components,
      muted,
      recommendations,
      shouldSubscribeToTimeline,
      focusOnElement,
      initialSeek,
      calculateTimestampOffsetForEachSegment,
    } = Object.assign(
      {
        containerWrapperId: '',
        assetId: '',
        components: COMPONENTS_PRESETS.DEFAULT,
        muted: false,
        recommendations: false,
        shouldSubscribeToTimeline: false,
        focusOnElement: true,
        initialSeek: false,
        calculateTimestampOffsetForEachSegment: false,
      },
      options,
    );

    this.containerWrapperId = containerWrapperId;
    this.containerSelector = `#${containerWrapperId} .${GENERATED_PLAYER_CONTAINER}`;
    this.assetId = assetId;
    this.letterbox = null;

    this.muted = muted;
    this.recommendations = recommendations;
    this.shouldSubscribeToTimeline = shouldSubscribeToTimeline;
    this.calculateTimestampOffsetForEachSegment =
      calculateTimestampOffsetForEachSegment;
    this.focusOnElement = focusOnElement;
    this.components = components;

    if (initialSeek !== false) {
      this.initialSeek = parseInt(initialSeek, 10);
    }

    // create a wrapper for the letterbox player
    $(`#${this.containerWrapperId}`).append(
      $(
        `<div class="${GENERATED_PLAYER_CONTAINER} player-wrapper__player-container" />`,
      ),
    );

    if (!window.SRGLetterbox) {
      // load the player assets dynamically if not previously loaded (plugin-loader or other player instance)
      import(
        /* webpackChunkName: "letterbox-web-css" */ '@srgssr/srgletterbox-web/dist/letterbox.css'
      ).then(() =>
        import(
          /* webpackChunkName: "letterbox-web-js" */ '@srgssr/srgletterbox-web/dist/letterbox.js'
        ).then((module) => {
          window.SRGLetterbox = module.default;
          this.init();
        }),
      );
    } else {
      this.init();
    }
  }

  init() {
    const config = getConfig();
    const isRtr = config.BUSINESS_UNIT === 'rtr';

    //player default options see: https://letterbox-web.srgssr.ch/production/api/global.html#LetterboxConfiguration
    this.letterbox = new SRGLetterbox({
      debug: false,
      container: this.containerSelector,
      ilHost: config.LETTERBOX_PLAYER_IL_HOST,
      fillMode: true,
      playerFocus: { preventScroll: false },
      language: isRtr ? 'rm' : 'de',
      autoplay: this.getAutoplaySettingByOs(),
      continuousPlayback: false,
      components: this.components,
      muted: { default: this.muted, storage: false },
      textTrackLanguage: { default: isRtr ? 'de' : false, storage: true },
      textTrackSize: { default: 0, storage: true },
      recommendations: this.recommendations,
      calculateTimestampOffsetForEachSegment:
        this.calculateTimestampOffsetForEachSegment,
    });

    this.registerListeners();

    if (isChrome() && this.muted) {
      // fix for android: chrome sometimes forgets the 'muted' setting between instantiation and playback
      // if the player is initially muted, autoplay is deactivated and programmatically triggered on 'canplay'
      this.letterbox.one('canplay', () => {
        this.letterbox.autoplay('muted');
      });
    }
    this.load(this.assetId, this.focusOnElement);

    if (this.shouldSubscribeToTimeline) {
      this.subscribeToTimeline();
    }
  }

  getAutoplaySettingByOs() {
    if (this.muted) {
      if (isChrome()) {
        // fix for android: chrome sometimes forget the 'muted' setting between instantiation and playback
        // if the player is initially muted, autoplay is deactivated and programmatically triggered on 'canplay'
        return false;
      } else {
        return 'muted';
      }
    } else {
      // by default, we use autoplay
      return true;
    }
  }

  focusOnPlayer() {
    if (this.focusOnElement) {
      // only set the focus once
      this.focusOnElement = false;

      // player is not always ready immediately - to be fixed once it's clear
      window.setTimeout(() => {
        this.letterbox.player.focus();
      }, 250);
    }
  }

  registerListeners() {
    this.letterbox.on('firstplay', () => this.focusOnPlayer());

    this.letterbox.one('ready', () => triggerEvent(MEDIA_READY));

    this.letterbox.on('fullscreenchange', () =>
      triggerEvent(MEDIA_FULLSCREEN_CHANGE),
    );

    this.letterbox.on('canplay', () =>
      triggerEvent(MEDIA_CAN_PLAY, {
        id: this.containerWrapperId,
        assetId: this.assetId,
      }),
    );

    this.letterbox.on('playing', () =>
      triggerEvent(MEDIA_PLAYING, {
        id: this.containerWrapperId,
        assetId: this.assetId,
      }),
    );

    this.letterbox.on('play', () => {
      // temp workaround for analytic problems with multiple players on one page
      // see https://srgssr-ch.atlassian.net/browse/ADI-267
      // see https://srgssr-ch.atlassian.net/browse/SRFCMSAL-7965
      this.letterbox.player
        .options()
        .SRGProviders.analytics.getInternalLabels();
      // Reload the container so that internal variables are updated
      this.letterbox.player
        .options()
        .SRGProviders.analytics.loadTagCommanderContainer();
    });

    this.letterbox.on('ended', () =>
      triggerEvent(MEDIA_ENDED, {
        id: this.containerWrapperId,
        assetId: this.assetId,
      }),
    );

    this.letterbox.on('error', () => {
      triggerEvent(MEDIA_ERROR, {
        id: this.containerWrapperId,
        assetId: this.assetId,
      });

      this.focusOnPlayer();
    });

    this.letterbox.on('pause', () =>
      triggerEvent(MEDIA_PAUSED, {
        id: this.containerWrapperId,
        assetId: this.assetId,
      }),
    );

    this.letterbox.on('volumechange', () =>
      triggerEvent(MEDIA_VOLUME_CHANGED, {
        id: this.containerWrapperId,
        assetId: this.assetId,
        value: this.letterbox.volume(),
        muted: this.getMuted(),
      }),
    );
  }

  goLive() {
    this.letterbox?.player?.liveTracker?.seekToLiveEdge();
    this.play();
  }

  getSeekableRange() {
    if (this.letterbox) {
      const start = this.letterbox.player.seekable().start(0);
      const end = this.letterbox.player.seekable().end(0);

      return {
        start: start,
        end: end,
        duration: end - start,
      };
    }
  }

  goToDatetime(targetDateTime) {
    this.pause();
    this.letterbox.one('playing', () =>
      this.letterbox?.currentDate(targetDateTime),
    );
    this.play();
  }

  goToPercentage(targetPercentage) {
    if (this.letterbox) {
      const { start, duration } = this.getSeekableRange();
      const timeInSeconds = duration * (targetPercentage / 100);
      this.letterbox.currentTime(start + timeInSeconds);
    }
  }

  play() {
    if (this.letterbox) {
      this.letterbox.play();
    }
  }

  pause() {
    if (this.letterbox) {
      this.letterbox.pause();
    }
  }

  skip(seconds) {
    if (this.letterbox) {
      const current = this.letterbox.currentTime();
      // Letterbox handles the boundaries (e.g. not going into the future)
      this.letterbox.currentTime(current + seconds);
    }
  }

  setMuted(newMutedState) {
    this.letterbox?.muted(newMutedState);
  }

  getMuted() {
    return this.letterbox?.muted();
  }

  setVolume(vol) {
    // if we want to change the volume, it shouldn't be muted
    this.setMuted(false);
    this.letterbox?.volume(vol);
  }

  getVolume() {
    return this.letterbox?.volume();
  }

  destroy() {
    if (this.letterbox) {
      this.letterbox.dispose();
    }
  }

  exitPipIfOpen() {
    if (this?.letterbox?.player.isInPictureInPicture()) {
      this.letterbox.player.exitPictureInPicture();
    }
  }

  requestFullscreen() {
    this?.letterbox?.player.requestFullscreen();
  }

  unsubscribeFromTimeline() {
    this.letterbox.off('timeupdate');
  }

  subscribeToTimeline() {
    this.letterbox.on('timeupdate', () => {
      const seconds = this.letterbox.player.currentTime();
      let duration = this.letterbox.player.duration();

      // for streams, there is no duration
      if (duration === Infinity) {
        duration = this.getSeekableRange().duration;
      }

      const percentage = (seconds / duration) * 100;
      // make sure percentage is always between 0 and 100
      const clampedPercentage = Math.min(Math.max(percentage, 0), 100);

      const payload = {
        id: this.containerWrapperId,
        assetId: this.assetId,
        seconds: seconds,
        duration: duration,
        percentage: clampedPercentage,
      };

      // we don't always know if the source is DVR when loading it
      if (
        this.letterbox?.player?.currentSource()?.streamType?.toLowerCase() ===
        'dvr'
      ) {
        const currentDate = this.getCurrentDate();
        if (currentDate) {
          payload.datetime = currentDate;
        }
      }

      triggerEvent(MEDIA_TIME_UPDATE, payload);
    });
  }

  getCurrentDate() {
    // letterbox.js offers no obvious way to check if currentDate() can be
    // called, it just throws an error. Because we don't want to pass that
    // error on to users of this function, we catch it here.
    try {
      return this.letterbox.currentDate();
    } catch (e) {
      return null;
    }
  }

  setOptionsForLiveStreamType(type) {
    let flag = false;
    if (type === 'audio') {
      // audio livestreams must use the calculateTimestampOffsetForEachSegment option
      // see: https://github.com/SRGSSR/srgletterbox-web/commit/f52fb2df8442f48fcf8c1e0815c6b367d53799fa
      flag = true;
    }
    this.letterbox.updateConfiguration({
      calculateTimestampOffsetForEachSegment: flag,
    });
  }

  isLive() {
    return this.letterbox?.player?.liveTracker?.atLiveEdge();
  }

  disableControls() {
    this.letterbox.player.controls(false);
  }

  enableControls() {
    this.letterbox.player.controls(true);
  }

  getCurrentTime(cb) {
    const currentTime = this.letterbox.currentTime();
    cb(currentTime);
  }

  getCurrentUrn() {
    return this.letterbox?.getUrn();
  }

  load(urn, focusOnElement = false) {
    this.assetId = urn;
    this.focusOnElement = focusOnElement;

    let options = {
      standalone: true,
    };

    if (this.initialSeek) {
      options.pendingSeek = this.initialSeek;
    }

    this.letterbox.loadUrn(this.assetId, options);
  }
}
