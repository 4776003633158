export function init() {
  $('.js-show-detail').each((_, element) => {
    new ShowDetail(element);
  });
}

export class ShowDetail {
  constructor(element) {
    let $expandableContainer = $(element).find('.js-expandable-container');
    let $expandableLink = $expandableContainer.find('.js-expandable-link');
    let $expandableShortBody = $expandableContainer.find(
      '.js-expandable-body--short',
    );
    let $expandableBodyParts = $expandableContainer.find(
      '.show-detail__body-extension--hidden',
    );

    $expandableLink.on('click', (e) => {
      e.preventDefault();
      $expandableShortBody.remove();
      $expandableLink.remove();
      $expandableBodyParts.removeClass('show-detail__body-extension--hidden');
    });
  }
}
