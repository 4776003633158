import moment from 'moment';

const LOG = '.js-song-log';
const TEASER = '.js-song-log-teaser';
const CONTAINER = '.js-song-log-container';
const MAX_MINUTES = '10';
const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

class SongLog {
  constructor($log) {
    this.$log = $log;
    this.pollingUrl = $log.data('polling-url');
    this.channelId = $log.data('channel-id');
    this.isCompactMode = $log.data('compact-mode') === true;
    this.hideEmptyContainer = $log.data('hide-empty') === true;
    this.businessUnit = $('body').data('bu');
    this.$logList = $('ul, ol', $log).first();
    this.urlParams = new URLSearchParams();
    this.urlParams.append('from', this.getFromDate());
    this.urlParams.append('to', this.getToDate());
    this.urlParams.append('pageSize', $log.data('page-size'));

    this.fetchLogData().then((data) => {
      if (data.songList.length) {
        $(data.songList).each((key, value) => {
          /*
           *  value.playedDate date example: 2019-07-04T14:24:03+02:00
           *  we only need the minutes to show...
           */
          $(this.$logList).append(`
              <li class="songlog__entry">
                <div class="songlog__time">
                    ${value.date.substring(11, 16)}
                </div>
                <div class="songlog__info">
                    <span class="songlog__song-title">
                        ${value.title}
                    </span>
                    <span class="songlog__artist">
                        ${this.getPredicate(value.artist.name)}
                        ${value.artist.name}
                    </span>
                </div>
              </li>
            `);
        });

        // don't show played music in header, if the last played song started more then 10 minutes ago.
        if (
          this.getMinutesSinceLastSong(data.songList[0].date) <= MAX_MINUTES
        ) {
          $(this.$log).closest(TEASER).show();
        } else if (this.isCompactMode) {
          this.clearContainer();
        }
      } else if (this.isCompactMode || this.hideEmptyContainer) {
        this.clearContainer();
      } else {
        // "no data" message
        $(this.$log).append(`
                        <div class="log__info">
                            <span class="log__title">
                                Keine Daten verfügbar.
                            </span>
                        </div>
                      </li>
                    `);
      }
    });
  }

  getMinutesSinceLastSong($date) {
    let dateNow = moment();
    let lastSong = moment(new Date($date));

    return dateNow.diff(lastSong, 'minutes');
  }

  fetchLogData() {
    return $.ajax(
      `${this.pollingUrl}/${this.channelId}.json?${this.urlParams}`,
      {
        type: 'GET',
        cache: 'true',
      },
    );
  }

  getToDate() {
    let dateNow = moment().format(MOMENT_FORMAT);

    if (typeof this.$log.data('date-to') !== 'undefined') {
      dateNow = this.$log.data('date-to');
    }
    return dateNow;
  }

  getFromDate() {
    let fromDate = moment().add('-1', 'day').format(MOMENT_FORMAT);

    if (typeof this.$log.data('date-from') !== 'undefined') {
      fromDate = this.$log.data('date-from');
    }

    return fromDate;
  }

  clearContainer() {
    // a song log teaser can be placed in a container whose empty state is styled by using the css
    // :empty pseudo-class -> the container must be completely empty (not even whitespaces)
    $(CONTAINER).empty();
  }

  //translate predicate between song and artist
  getPredicate(name) {
    let translatedPredicate = 'von ';
    if (this.businessUnit === 'rtr') {
      if (/[aeiouäöüèéàì]/.test(name.charAt(0).toLowerCase())) {
        translatedPredicate = 'dad ';
      } else {
        translatedPredicate = 'da ';
      }
    }

    return this.isCompactMode ? translatedPredicate : '';
  }
}

export function init() {
  $(LOG).each((i, elem) => {
    new SongLog($(elem));
  });
}
