/*
 * This file handles the hover state of a medium molecule and the download button.
 * It can also be used on a media-still molecule if it is used as a standalone (=not a child of a medium) molecule.
 */

const HOVER_HOOK_SELECTOR_CLASS = 'js-medium-hover',
  HOVER_HOOK_SELECTOR = `.${HOVER_HOOK_SELECTOR_CLASS}`,
  HOVER_HOOK_IMAGE_SELECTOR_CLASS = 'js-medium-hover-image',
  HOVER_HOOK_IMAGE_SELECTOR = `.${HOVER_HOOK_IMAGE_SELECTOR_CLASS}`,
  DOWNLOAD_HOOK_SELECTOR_CLASS = 'js-medium-download-link',
  DOWNLOAD_HOOK_SELECTOR = `.${DOWNLOAD_HOOK_SELECTOR_CLASS}`;

export function init() {
  // making sure that the binding only occurs once per molecule
  $(`${HOVER_HOOK_SELECTOR} ${HOVER_HOOK_SELECTOR}`).removeClass(
    HOVER_HOOK_SELECTOR_CLASS,
  );

  // no hover on 'medium--no-hover' elements
  // (HOVER_HOOK_SELECTOR_CLASS and medium--no-hover shouldn't be used on the same element anyway
  //  but sometimes its too cumbersome to implement in FEF/patternlab)
  $(`${HOVER_HOOK_SELECTOR}.medium--no-hover`).removeClass(
    HOVER_HOOK_SELECTOR_CLASS,
  );

  // hover for medium element
  $(document).on('mouseenter', HOVER_HOOK_SELECTOR, (event) => {
    let $element = $(event.target).closest(HOVER_HOOK_SELECTOR);

    // option to disable the hover state of the media still
    let disableStillHover = $element.data('disable-still-hover') || false;
    // option to have the icon show up on medium hover, but only turning red on media-still hover
    let twoTierHover = $element.data('two-tier-hover') || false;

    if (twoTierHover) {
      $element
        .find('.media-still__image')
        .addClass('media-still__image--overall-hover');
      return;
    }

    $element.find('.media-caption').addClass('media-caption--hover');
    $element.find('.play-icon').addClass('play-icon--hover');

    if (disableStillHover) {
      $element
        .find('.media-still__image')
        .addClass('media-still__image--no-hover');
    } else {
      $element
        .find('.media-still__image')
        .addClass('media-still__image--hover');
    }
  });

  $(document).on('mouseleave', HOVER_HOOK_SELECTOR, (event) => {
    let $element = $(event.target).closest(HOVER_HOOK_SELECTOR);
    $element.find('.media-caption').removeClass('media-caption--hover');
    $element.find('.play-icon').removeClass('play-icon--hover');
    $element
      .find('.media-still__image')
      .removeClass(
        'media-still__image--hover media-still__image--overall-hover',
      );
  });

  $(document).on('mouseenter', HOVER_HOOK_IMAGE_SELECTOR, (event) => {
    let $element = $(event.target).closest(HOVER_HOOK_IMAGE_SELECTOR);
    $element.find('.media-still__image').addClass('media-still__image--hover');
    $element.find('.play-icon').addClass('play-icon--hover');
  });

  $(document).on('mouseleave', HOVER_HOOK_IMAGE_SELECTOR, (event) => {
    let $element = $(event.target).closest(HOVER_HOOK_IMAGE_SELECTOR);
    $element
      .find('.media-still__image')
      .removeClass('media-still__image--hover');
    $element.find('.play-icon').removeClass('play-icon--hover');
  });

  // clicking on the download-button should change the icon
  $(DOWNLOAD_HOOK_SELECTOR).on('click', (event) => {
    window.setTimeout(() => {
      let $icon = $(event.currentTarget).find('.js-download-icon');

      $icon
        .removeClass('svg-icon--download svg-icon--download-red')
        .addClass('svg-icon--download-success');
    }, 1000);
  });
}
