export const showCollection = (collectionElement) => {
  collectionElement.classList.remove('collection-ng--hidden');
};

export const addTeasersToCollection = (collection, teasers) => {
  const teaserList = collection.querySelector('.js-teaser-ng-list');
  if (!teaserList) {
    return;
  }

  const listToAdd = teasers
    .map(
      (teaser) =>
        `<li class="collection-ng__teaser-item js-teaser-ng-item">${teaser.trim()}</li>`,
    )
    .join('');

  teaserList.innerHTML += listToAdd;
};
