/*
 * This file exists solely for nasty browser hacks, fallbacks and fixes.
 *
 * It is envisaged that we DELETE the code-snippets as soon as support for each targeted browser is deprecated. Therefore watch out for the «TARGETED BROWSER»-comments at the beginning of each function in this file.
 *
 */

export function init() {
  addBrowserClass();
}

/*
 * TARGETED BROWSER: Safari Mobile in iOS 12
 * Our implementation of the swipeable-area causes Safari in iOS 12
 * curiously to flicker when scrolling.
 */

function addBrowserClass() {
  let browser = (function () {
    let check = function (regexp) {
      return regexp.test(window.navigator.userAgent);
    };
    switch (true) {
      case check(/iPhone OS 12/i):
        return 'safari-ios-12';
      default:
        return;
    }
  })();

  if (browser) {
    document.body.classList.add(browser);
  }

  /* Bug-Fixes and Stylings via `_shame.scss` */
}

export const supportsScrollAnchoring =
  document.documentElement.style.hasOwnProperty('overflowAnchor');

/*
 * TARGETED BROWSER: browsers that don't support Scroll-Anchoring, i.e. Safari
 * See: https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-anchor/Guide_to_scroll_anchoring
 *
 * In short: most browsers do not move the viewport around when content above
 * the viewport changes. This is pretty nice, because it prevents the user from
 * accidentally clicking on something that moved "under" the pointer/finger just
 * as they were about to click something else. Also, it looks nicer.
 * But: Safari does not support this feature.
 *
 * In livetickers, we often have some 3rd party elements before the actual
 * ticker content, which provides context to the liveticker. These elements
 * often resize themselves after they loaded some external content, e.g. maps,
 * social media posts, etc. This causes the viewport to jump around in Safari.
 *
 * This function aims to prevent this unwanted behaviour by
 * - observing the size of the body and checking if the content before the
 *   element we want to have affixed to the top of the screen changed its size
 * - preventing the viewport from scrolling to the new position
 * - aborting the above listener when the user scrolls manually (because then
 *   it would be wrong to not allow the viewport to scroll)
 */
export const preventUnwantedScroll = (element, scrollFn) => {
  let lastYPosOfElement = element.getBoundingClientRect().top + window.scrollY;

  const resizeObserver = new ResizeObserver(() => {
    const newYPosOfElement =
      element.getBoundingClientRect().top + window.scrollY;

    if (lastYPosOfElement !== newYPosOfElement) {
      // size of the content before the element changed!
      stopScrollListener();
      // scroll back to element
      scrollFn();
      requestAnimationFrame(startScrollListener);
    }

    lastYPosOfElement = newYPosOfElement;
  });

  const startResizeListener = () => resizeObserver.observe(document.body);
  const stopResizeListener = () => resizeObserver.unobserve(document.body);

  const startScrollListener = () => document.addEventListener('scroll', stop);
  const stopScrollListener = () => document.removeEventListener('scroll', stop);

  const stop = () => {
    stopResizeListener();
    stopScrollListener();
  };

  // start listening to resize and scroll events
  startResizeListener();
  startScrollListener();
};
