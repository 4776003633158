import { SRFBridge } from 'srf-native-bridge';
import { getConfig } from '@fec/assets/js/utils/config';
import { Webtrekk } from '../../common/js/analytics/Webtrekk';

export function init() {
  $('.js-search').on('submit', (e) => {
    // search forms
    submitCustomEvent($(e.currentTarget));
  });

  $('.js-search-results').on('click', '.search-result__link', (e) => {
    // typeahead
    submitHiddenEvent($(e.currentTarget));
  });
}

// hidden event for search result (because there's no page load on search result page)
function submitCustomEvent($searchForm = null) {
  const CONFIG = getConfig();
  const $inputField = $searchForm.find('.js-search-input');

  if (CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT && $inputField) {
    let webtrekk = new Webtrekk(CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT);
    let selectedDateOptionStr = '';

    let selectedDateOption = $('.search-date-filter--selected')
      .find('a')
      .html();
    // Beliebige Zeit: dateFilter is not active
    if (
      typeof selectedDateOption !== 'undefined' &&
      selectedDateOption !== '' &&
      selectedDateOption !== 'Beliebige Zeit'
    ) {
      selectedDateOptionStr = '-Filter:' + selectedDateOption;
    }
    let searchTerm = $inputField.val().toLowerCase();
    let eventValue =
      String($searchForm.find('.js-search-results li').length) +
      selectedDateOptionStr;
    if (SRFBridge.isRunningInApp()) {
      let metadata = {
        webtrekk: {
          event_source: 'search_result',
          event_name: searchTerm,
          event_value: eventValue,
          event_type: 'hidden_event',
        },
      };
      SRFBridge.trackEvent(metadata);
    } else {
      webtrekk.sendInfo({
        linkId: 'hidden_event',
        customClickParameter: {
          1: $inputField.data('referrer-track').split('srg_mod1=')[1] || '', // field
          3: searchTerm, // keyword
          4: eventValue, // typeahead length (must be a string)
        },
      });
    }
  }
}

// hidden event for typeahead (because there's no module measurement in play)
// typeahead does not exist in App
function submitHiddenEvent($link = null) {
  const CONFIG = getConfig();
  const $linkDataArray = $link.data('referrer-track').split('&');

  const $searchForm = $link.closest('form');
  const $inputField = $searchForm.find('.js-search-input');
  const inputData = $inputField.data('referrer-track');

  if (CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT && $linkDataArray.length > 2) {
    let webtrekk = new Webtrekk(CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT);

    let payloadCustomClickParameter = {
      1: inputData.split('srg_mod1=')[1],
      3:
        $link.find('.h-offscreen').text().toLowerCase() +
          '|' +
          $linkDataArray[2].split('srg_override_mod3=')[1] || '', // Show | keyword
    };

    if ($linkDataArray[3]) {
      payloadCustomClickParameter[4] =
        $linkDataArray[3].split('srg_override_mod4=')[1];
    }

    webtrekk.sendInfo({
      linkId: 'click',
      customClickParameter: payloadCustomClickParameter,
    });
  }
}
