import {
  SHOW_HIDDEN_TEASER,
  SWIPEABLE_CONTENT_CHANGED,
  triggerEvent,
} from '@fec/assets/js/utils/event';
import ClientStorage from '@fec/frontend/foundation/client/storage';
import moment from 'moment';
import {
  addTeasersToCollection,
  showCollection,
} from '@fec/frontend/modules/collections/collection-ng/collection-ng';

const STORAGE_KEY = 'srf:item:podcast:history';

export function init() {
  const $collection = $('.js-static-item-podcast-history');
  const trackInfo = $('meta[property="srf:podcast-history-urn"]').attr(
    'content',
  );

  // if we're on an episode page, track this visit
  if (trackInfo && window.location.hash.includes('autoplay')) {
    track(trackInfo);
  }

  // only act if the podcast history collection is present
  if ($collection.length === 0) {
    return;
  }

  // get history entries from localstorage, filter for shows and limit to 6
  const seenItems = getList()
    .filter((item) => item.urn.includes(':show:'))
    .map((item) => item.urn)
    .reverse()
    .slice(0, 6);

  // only act if something is in the history
  if (seenItems.length === 0) {
    return;
  }

  // kind of a funny story: jquery has its own promise implementation,
  // so we assemble all promises in get and save their async response in a hash map
  let gets = [];
  let responses = [];

  seenItems.map((urn) => {
    // get the teaser's html and put it in a hashmap
    gets.push(
      $.get(`assetgroup/${urn}/teaser`, (data) => {
        responses[urn] = data;
      }),
    );
  });

  // if this then that.
  $.when(...gets).then(() => {
    // again, but with content.
    const teaserList = seenItems.map((urn) => responses[urn]);
    addTeasersToCollection($collection[0], teaserList);
    showCollection($collection[0]);
    triggerEvent(SWIPEABLE_CONTENT_CHANGED, null, $collection[0]);

    // make sure images are being lazyloaded and the progress bar is added
    triggerEvent(SHOW_HIDDEN_TEASER);
  });
}

function track(urn) {
  //we only show 6 episodes, but leave some room for errors
  const MAX_ENTRIES = 10;
  // keep the queue at limits
  let list = getList().slice(-MAX_ENTRIES);

  // remove view entry for same urn (to update the timestamp later on)
  list = list.filter((entry) => entry.urn !== urn);

  // add current view event to queue
  list.push({
    urn: urn,
    timestamp: moment().format(),
  });

  // persist
  ClientStorage.setItemJsonStringified(STORAGE_KEY, list);
}

function getList() {
  return ClientStorage.getItemJsonParsed(STORAGE_KEY, []);
}
